import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../assets/styles/variables';

const List = styled.ul`
  li {
    border-bottom: 1px solid ${COLORS.border};
    padding: 10px 0 15px;
  }
  li:last-child {
    border: none;
  }
  li::before {
    content: ''; /* Add content: \\2022 is the CSS Code/unicode for a bullet */
    background-image: url(${require('../assets/images/icons/check.svg')});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 2em; /* Also needed for space (tweak if needed) */
    height: 1em;
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
`;

const StyledList = ({ children }) => {
  return <List>{children}</List>;
};

export default StyledList;
