import React from 'react';
import SectionHeaderMainText from '../SectionHeader/SectionHeaderMainText';
import SectionHeaderSubText from '../SectionHeader/SectionHeaderSubText';
import SolutionCardImage from '../SolutionCard/SolutionCardImage';
import { COLORS } from '../../assets/styles/variables';
import styled, { css } from 'styled-components';
import { mobile, phone } from '../../utils/media';
import Text from '../../atoms/Text';
import Spacer from '../../atoms/Spacer';

const CardWrapper = styled.div`
  background: ${COLORS.cardBg};
  padding: 20px;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;

  ${mobile(css`
    padding: 30px 60px;
    gap: 20px;
  `)}

  ${phone(css`
    padding: 20px;
    gap: 20px;
  `)}
`;

export const CardFooter = styled.div`
  display: flex;
  flex-grow: 0;
  justify-content: flex-end;
  flex-direction: column;
  justify-self: flex-end;
`;

const ProductCard = ({ title, description, image, productName, actions, note }) => {
  return (
    <CardWrapper>
      {title && (
        <SectionHeaderMainText
          transform="none"
          align="center"
          weight="bold"
          size="24px"
          height="50px"
          lineHeight="1.2"
          color={COLORS.greyText}
        >
          {title}
        </SectionHeaderMainText>
      )}
      {image && <SolutionCardImage image={image} />}
      {productName && (
        <SectionHeaderMainText
          transform="none"
          align="center"
          weight="bold"
          size="32px"
          lineHeight="1.2"
          color={COLORS.greyText}
        >
          {productName}
        </SectionHeaderMainText>
      )}
      <SectionHeaderSubText block size="20px" color={COLORS.greyText} lineHeight="1.2">
        {description}
      </SectionHeaderSubText>
      <CardFooter>
        {note && (
          <>
            <Text size={'14px'} color={COLORS.greyText}>
              {note}
            </Text>
            <Spacer height="30px" />
          </>
        )}
        {actions}
      </CardFooter>
    </CardWrapper>
  );
};

export default ProductCard;
