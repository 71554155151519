import React from 'react';
import SolutionCard from '../SolutionCard/SolutionCard';
import SectionHeaderMainText from '../SectionHeader/SectionHeaderMainText';
import styled, { css } from 'styled-components';
import ForwardLink from '../../molecules/ForwardLink';
import Text from '../../atoms/Text';
import { COLORS } from '../../assets/styles/variables';
import Button from '../../atoms/Button';
import { mobile } from '../../utils/media';

const ItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 70px;
  flex-grow: 1;
  ${mobile(css`
    flex-direction: column;
    justify-content: space-between;
  `)}
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  width: 50%;
`;

const ItemImage = styled.div`
  display: block;
  aspect-ratio: 16 / 9;
  width: 50%;
  max-width: 350px;
  background-image: url(${({ image }) => (image.isLocal ? require('../../assets/images/desktop/' + image.link) : image.link)});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  flew-shrink: 0;
  flex-grow: 0;
  ${mobile(css`
    width: 100%;
  `)};
`;

const ExploreCard = ({ title, items, t }) => {
  return (
    <SolutionCard>
      <SectionHeaderMainText
        transform="none"
        align="center"
        weight="bold"
        size="32px"
        lineHeight="1.2"
        color={COLORS.greyText}
      >
        {title}
      </SectionHeaderMainText>
      {items.map((item) => {
        return (
          <ItemWrapper key={item.id}>
            <ItemImage image={item.image} />
            <InfoWrapper>
              <Text weight={'bold'} align={'center'} block size={'20px'}>
                {t(`app:explore:${item.id}`)}
              </Text>
              <ForwardLink target={'_BLANK'} linkTo={item.action.linkTo || '#'}>
                <Button bgColor={COLORS.primary}>{t(item.action.label)}</Button>
              </ForwardLink>
            </InfoWrapper>
          </ItemWrapper>
        );
      })}
    </SolutionCard>
  );
};

export default ExploreCard;
